import React, { useContext, useRef } from "react";

import {isEmpty} from "../helpers/functions";
import { Toast } from 'primereact/toast';


const ToastContext = React.createContext({});
const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  const defaultPayload = {
    severity: 'info',
    summary: 'Sistema:',
    life: 3000
  };

  function showToast (payload, type = 'info') {
    if (toastRef == null) {
      return;
    }


    switch(type) {
      case 'success':
        if (isEmpty(payload.summary)) payload.summary = `Sucesso`;
        payload.severity = 'success';
        break;
      case 'error':
        if (isEmpty(payload.summary)) payload.summary = `Erro`;
        payload.severity = 'error';
        break;
      case 'warning':
        if (isEmpty(payload.summary)) payload.summary = `Atenção`;
        payload.severity = 'warning';
        break;
      case 'secondary':
        payload.severity = 'secondary';
        break;
      default:
        payload.severity = 'error';
    }

    const finalPayload = { ...defaultPayload, ...payload };

    toastRef.current.show(finalPayload);
  }

  function clearToasts() {
    (toastRef != null) && toastRef.current.clear();
  }

  return (
    <ToastContext.Provider value={{ showToast, clearToasts }}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};

const useToastContext = () => useContext(ToastContext);

export { useToastContext, ToastProvider };

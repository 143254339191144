import {PROFESSIONAL} from "./constants";

export function setAppTitle(title) {
  if (isEmpty(title)) return;
  document.title = `${title} - ${PROFESSIONAL.name}`;
}

export const isArray = (obj) => Array.isArray(obj);

export const isZero = (str) => {
  try {
    str *= 1;
    if (str === 0) { return true; }
  } catch (e) { }
  return false;
};

export const isEmpty = (str, zeroIsEmpty = true) => {
  if (str === null || str === false || str === undefined ||
    (isArray(str) && str.length === 0)
  ) {
    return true;
  } else if (isArray(str) && str.length > 0) { return false; }

  try {
    str = str.toString().trim().toLowerCase();
    if (
      str === "" || str === "false" ||
      str === "null" || str === "undefined" ||
      str === "nan"
    ) { return true; }
  } catch (e) { }

  if (zeroIsEmpty) return isZero(str);
  return false;
};


// element toggle function
export const elementToggleFunc = (elem) => { elem && elem.classList.toggle("active"); }

export const PROFESSIONAL= {
    "name": "Roberto Silva",
    "headline": "Full-stack web developer",
    "birthday": "July, 1999",
    "avatar": <img src="images/my-avatar.png" alt="Roberto Silva" width="80"/>,
};

export const CONTACT = {
    'email': 'contato@robertosilva.dev',
    'phone': '+55 (38) 99183-4230',
}
export const API_URL= "https://api.cursos.victall.com.br";

export const SOCIAL_MEDIAS= {
    instagram: "victall_br",
    facebook: "victall_br",
    youtube: "victall_br",
    whatsapp: "3891309002"
};
export const ADDRESS = {
    street: "Rua Monte Plano",
    number: "651 A",
    block: "Santa Rita 1",
    city: "Montes Claros",
    state: "MG",
    zipcode: "39400-392",
    country: "Brazil",
}

export const ABOUT = {
    resume: `
        Full-Stack Web Developer with over 8 years of experience, specializing in backend development with PHP and Python. I excel in creating robust back-end systems while also managing front-end tasks using modern JavaScript frameworks.<br>
        <br>✔️ Developing and maintaining scalable back-end systems using PHP (CodeIgniter, Laravel) and Python (Django, Flask);
        <br>✔️ Designing and optimizing databases with SQL;
        <br>✔️ Building and integrating SaaS solutions to drive business efficiency;
        <br>✔️ Creating responsive and dynamic front-ends using React and Vue.js;
        <br>✔️ Implementing data security measures, especially in API development;
        <br>✔️ API performance tuning and optimization to enhance responsiveness and scalability;
        <br>✔️ Proficient in version control systems (Git, GitHub, GitLab);
        <br>✔️ Experienced in software prototyping and wireframing to streamline development processes.
        
        <br><br>Other Skills:
        
        <br>✔️ Strong problem-solving skills with a focus on performance and scalability;
        <br>✔️ Experience working remotely in teams that use agile practices.
        <br>✔️ Proven ability to deliver high-quality, secure, and maintainable software solutions;
        <br>✔️ Passionate about backend architecture and driving innovation in SaaS applications;
        
        <br><br>Hobbies:
        
        <br>✔️ Continuously exploring advancements in backend technologies and SaaS;
        <br>✔️ Enjoys tackling complex full-stack projects that require innovative solutions.
    `,
    services: [
        {
            'title': 'Full-Stack Web Development',
            'description': 'Complete development of web applications, from front-end to back-end, ensuring functionality, performance, and seamless integration.',
            'icon': <img src="/images/icon-dev.svg" alt="Web development icon" width="40"/>,
        },
        {
            'title': 'SaaS Solutions',
            'description': 'Creation and maintenance of SaaS (Software as a Service) systems, optimizing processes and providing scalable solutions for various businesses.',
            'icon': <img src='/images/icon-design.svg' alt="design icon" width="40"/>,
        },
        {
            'title': 'RESTful API Development',
            'description': 'Building robust and secure APIs to enable efficient communication between platforms and ensure smooth integration with other services.',
            'icon': <img src='/images/icon-design.svg' alt="design icon" width="40"/>,
        },
        {
            'title': 'Database Management',
            'description': 'Designing and optimizing databases, ensuring scalability, security, and performance in applications that require high availability and consistency.',
            'icon': <img src='/images/icon-design.svg' alt="design icon" width="40"/>,
        }
    ],
    'testimonials': [
        {
            name: "Emily Evans",
            text: `Roberto was hired to create a corporate identity. We were very pleased with the work done. She has a
                            lot of experience
                            and is very concerned about the needs of client. Lorem ipsum dolor sit amet, ullamcous cididt
                            consectetur adipiscing
                            elit, seds do et eiusmod tempor incididunt ut laborels dolore magnarels alia.`,
            avatar: <img src="/images/avatar-3.png" alt="Daniel lewis" width="60" data-testimonials-avatar />,
        },
        {
            name: "Jessica Miller",
            text: `Roberto was hired to create a corporate identity. We were very pleased with the work done. She has a
                            lot of experience
                            and is very concerned about the needs of client. Lorem ipsum dolor sit amet, ullamcous cididt
                            consectetur adipiscing
                            elit, seds do et eiusmod tempor incididunt ut laborels dolore magnarels alia.`,
            avatar: <img src="/images/avatar-2.png" alt="Daniel lewis" width="60" data-testimonials-avatar />,
        },
        {
            name: "Pedro Lucas",
            text: `Roberto was hired to create a corporate identity. We were very pleased with the work done. She has a
                            lot of experience
                            and is very concerned about the needs of client. Lorem ipsum dolor sit amet, ullamcous cididt
                            consectetur adipiscing
                            elit, seds do et eiusmod tempor incididunt ut laborels dolore magnarels alia.`,
            avatar: <img src="/images/avatar-1.png" alt="Daniel lewis" width="60" data-testimonials-avatar />,
        },
        {
            name: "José Paulo Freitas",
            text: `Roberto was hired to create a corporate identity. We were very pleased with the work done. She has a
                            lot of experience
                            and is very concerned about the needs of client. Lorem ipsum dolor sit amet, ullamcous cididt
                            consectetur adipiscing
                            elit, seds do et eiusmod tempor incididunt ut laborels dolore magnarels alia.`,
            avatar: <img src="/images/avatar-4.png" alt="Daniel lewis" width="60" data-testimonials-avatar />,
        },
    ]
}
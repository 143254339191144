import React, {useEffect} from 'react';
import {elementToggleFunc} from '../helpers/functions';
import {CONTACT, PROFESSIONAL, ADDRESS} from '../helpers/constants';

export default function MenuSideLeft(){
    const toggleInfos = () => {
        elementToggleFunc(document.querySelector("[data-sidebar]")); 
    }

    return (
        <>
            <aside className="sidebar" data-sidebar>
                <div className="sidebar-info">

                <figure className="avatar-box">
                    {PROFESSIONAL.avatar}
                </figure>

                <div className="info-content">
                    <h1 className="name" title="Richard hanrick">{PROFESSIONAL.name}</h1>
                    <p className="title">{PROFESSIONAL.headline}</p>
                </div>

                <button className="info_more-btn" data-sidebar-btn onClick={toggleInfos}>
                    <span>Show contacts</span>

                    <ion-icon name="chevron-down"></ion-icon>
                </button>

                </div>

                <div className="sidebar-info_more">

                <div className="separator"></div>

                <ul className="contacts-list">

                    <li className="contact-item">

                    <div className="icon-box">
                        <ion-icon name="mail-outline"></ion-icon>
                    </div>

                    <div className="contact-info">
                        <p className="contact-title">Email</p>

                        <a href={`mailto:${CONTACT.email}`} className="contact-link">{CONTACT.email}</a>
                    </div>

                    </li>

                    <li className="contact-item">

                    <div className="icon-box">
                        <ion-icon name="phone-portrait-outline"></ion-icon>
                    </div>

                    <div className="contact-info">
                        <p className="contact-title">Phone</p>

                        <a href={`tel:${CONTACT.phone.replace(/\D/g, '')}`} className="contact-link">{CONTACT.phone}</a>
                    </div>

                    </li>

                    <li className="contact-item">

                    <div className="icon-box">
                        <ion-icon name="calendar-outline"></ion-icon>
                    </div>

                    <div className="contact-info">
                        <p className="contact-title">Birthday</p>

                        <time dateTime="1982-06-23">{PROFESSIONAL.birthday}</time>
                    </div>

                    </li>

                    <li className="contact-item">

                    <div className="icon-box">
                        <ion-icon name="location-outline"></ion-icon>
                    </div>

                    <div className="contact-info">
                        <p className="contact-title">Location</p>

                        <address>
                            {`${ADDRESS.city}, ${ADDRESS.state}`}
                            <br/>
                            {`${ADDRESS.country}`}
                        </address>
                    </div>

                    </li>

                </ul>

                <div className="separator"></div>

                <ul className="social-list">

                    <li className="social-item">
                    <a href="#" className="social-link">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    </li>

                    <li className="social-item">
                    <a href="#" className="social-link">
                        <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                    </li>

                    <li className="social-item">
                    <a href="#" className="social-link">
                        <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                    </li>

                </ul>

                </div>
            </aside>
        </>
    )
}
import React, {useEffect} from 'react';
import './App.css';
import {setAppTitle} from "./helpers/functions";
import { ToastProvider } from "./hooks/useToast";
import MenuSideLeft from "./components/MenuSideLeft"
import Navbar from './components/Navbar';
import About from './components/About';
import Resume from './components/Resume.jsx';
import Portfolio from './components/Portfolio.jsx';
import Contact from './components/Contact.jsx';

function App(){
    useEffect(() => {
        setAppTitle();
    }, []);

  return (
      <>
          <ToastProvider>
            <main>
              <MenuSideLeft />
              <div className="main-content">
                <Navbar/>
                <About/>
                <Resume/>
                <Portfolio/>
                <Contact/>
              </div>
            </main>
          </ToastProvider>

      </>
  );
}

export default App;

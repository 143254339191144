import {useEffect, useState} from "react"
import {ABOUT} from "../helpers/constants";

export default function About(){
    const [isTestimonialModalActive, setIsTestimonialModalActive] = useState(false)
    const [modalInfo, setModalInfo] = useState({});

    const handleOpenModal = (testimonial) => {
        setModalInfo(testimonial);
        setIsTestimonialModalActive(true);
    };
    const CloseModal = () => {
        setModalInfo({});
        setIsTestimonialModalActive(false);
    };

    // const showTestimonialDetail = () => {
    //     // testimonials variables
    //     const modalContainer = document.querySelector("[data-modal-container]");
    //     const modalCloseBtn = document.querySelector("[data-modal-close-btn]");
    //     const overlay = document.querySelector("[data-overlay]");
    //
    //     // modal variable
    //     const modalImg = document.querySelector("[data-modal-img]");
    //     const modalTitle = document.querySelector("[data-modal-title]");
    //     const modalText = document.querySelector("[data-modal-text]");
    //
    //     modalContainer.classList.toggle("active");
    //     overlay.classList.toggle("active");
    //
    //     modalImg.src = this.querySelector("[data-testimonials-avatar]").src;
    //     modalImg.alt = this.querySelector("[data-testimonials-avatar]").alt;
    //     modalTitle.innerHTML = this.querySelector("[data-testimonials-title]").innerHTML;
    //     modalText.innerHTML = this.querySelector("[data-testimonials-text]").innerHTML;
    //
    //     modalCloseBtn && modalCloseBtn.addEventListener("click", testimonialsModalFunc);
    //     overlay && overlay.addEventListener("click", testimonialsModalFunc);
    // };

    return (
        <>
            <article className="about  active" data-page="about">
                <header>
                    <h2 className="h2 article-title">About me</h2>
                </header>

                <section className="about-text">
                    <p dangerouslySetInnerHTML={{ __html: ABOUT.resume}} />
                </section>

                <section className="service">
                    <h3 className="h3 service-title">Services</h3>
                    <ul className="service-list">
                        {
                            (ABOUT.services ?? []).map((service, idx) =>
                                <li className="service-item" key={idx}>
                                    <div className="service-icon-box">
                                        {service?.icon}
                                    </div>
                                    <div className="service-content-box">
                                        <h4 className="h4 service-item-title">{service?.title}</h4>
                                        <p className="service-item-text">
                                            {service?.description}
                                        </p>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </section>

                <section className="testimonials">
                    <h3 className="h3 testimonials-title">Testimonials</h3>
                    <ul className="testimonials-list has-scrollbar">
                        {
                            (ABOUT.testimonials ?? []).map((testimonial, idx) =>
                                <li className="testimonials-item" key={idx}>
                                    <div className="content-card" onClick={() => handleOpenModal(testimonial)}>
                                        <figure className="testimonials-avatar-box">
                                            {testimonial.avatar}
                                        </figure>
                                        <h4 className="h4 testimonials-item-title">{testimonial.name}</h4>
                                        <div className="testimonials-text">
                                            <p>
                                                {testimonial.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </section>


                <div className={`modal-container ` + (isTestimonialModalActive ? 'active' : '')}>
                    <div className={`overlay ` + (isTestimonialModalActive ? 'active' : '')}></div>
                    <section className="testimonials-modal">
                        <button className="modal-close-btn" onClick={CloseModal}>
                            <ion-icon name="close-outline"></ion-icon>
                        </button>
                        <div className="modal-img-wrapper">
                            <figure className="modal-avatar-box">
                                {modalInfo?.avatar}
                            </figure>
                             <img src="/images/icon-quote.svg" alt="quote icon" />
                        </div>
                        <div className="modal-content">
                        <h4 className="h3 modal-title">{modalInfo?.name}</h4>
                        {/*<time>14 June, 2021</time>*/}
                        <div>
                            <p>
                                {modalInfo?.text}
                            </p>
                        </div>
                    </div>
                    </section>
                </div>

                <section className="clients">
                    <h3 className="h3 clients-title">Clients</h3>
                    <ul className="clients-list has-scrollbar">

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-1-color.png" alt="client logo" />
                            </a>
                        </li>

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-2-color.png" alt="client logo" />
                            </a>
                        </li>

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-3-color.png" alt="client logo" />
                            </a>
                        </li>

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-4-color.png" alt="client logo" />
                            </a>
                        </li>

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-5-color.png" alt="client logo" />
                            </a>
                        </li>

                        <li className="clients-item">
                            <a href="#">
                                <img src="/images/logo-6-color.png" alt="client logo" />
                            </a>
                        </li>
                    </ul>
                </section>

            </article>
        </>
    )
}